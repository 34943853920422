﻿import Vue from 'vue';
import vuetify from '@/plugins/vuetify-Pages.js';
import VueCookie from 'vue-js-cookie'
import '@/registerServiceWorker'

window.myfetch = function (resource, init)
{
    init = init || {};
    init.credentials = 'same-origin';

    return fetch(resource, init).then(function (response)
    {
        if (response.status == 405)
            document.location.assign(location.protocol + '//' + location.host);
        else
            return response;
    });
};
//requirejs.config({
//    baseUrl: 'Content/js/',
//    waitSeconds: 200,
//    paths: {
//        moment: 'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment.min.js',
//    }
//});

Vue.use(vuetify);
Vue.use(VueCookie);

const app = new Vue(
    {
        el: '#Main',
        vuetify,
        data:
        {
            mainStyle: "transform: none; background-image:url('Content/images/0" + (parseInt((Math.random() * 4) + 0.5) + 1).toString() + ".jpg');",
            BasePath: location.protocol + '//' + location.host + '/',
            login: false,
            errorText: null,
            errorCreateText: null,
            Saving: false,
            Environment: null,
            snackColor: 'success',
            snackVisible: false,
            snackText: null,
            LogonSpinner: false,
        },
        methods: {
            Login: function ()
            {
                this.LogonSpinner = true;

                var username = document.getElementById('txtUserName');
                var password = document.getElementById('txtPassword');
                var rememberme = document.getElementById('chkRememberMe');
                var _this = this;

                myfetch(this.BasePath + 'Login', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        Username: username.value,
                        Password: password.value,
                        RememberMe: rememberme.checked

                    }),
                })
                    .then(function (response)
                    {
                        _this.LogonSpinner = false;

                        if (response.ok)
                        {
                            const urlParams = new URLSearchParams(window.location.search);
                            let ReturnUrl = urlParams.get('ReturnUrl') || '';

                            ReturnUrl = ReturnUrl.replace(/(?:[a-z]+:)?\/\/[^\/]*/i, '');

                            if (ReturnUrl.length > 0)
                            {
                                window.location = ReturnUrl;
                            }
                            else
                            {
                                window.location.replace('Main');
                            }
                        }
                        else if (response.status == 400)
                        {
                            response.json().then(function (error)
                            {
                                _this.errorText = error.Error;
                            });
                        }
                    })
                    .catch(function (r)
                    {
                        _this.LogonSpinner = false;
                    });
            },
            CreateAccount: function ()
            {
                var username = document.getElementById('txtUserNameCreate');
                var email = document.getElementById('txtEmailCreate');
                var password = document.getElementById('txtPasswordCreate');
                var password2 = document.getElementById('txtConfirmCreate');
                var _this = this;
                this.Saving = true;
                myfetch(this.BasePath + 'Login/Create', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        Username: username.value,
                        Email: email.value,
                        Password: password.value,
                        Password2: password2.value,
                    }),
                })
                    .then(function (response)
                    {
                        _this.Saving = false;
                        if (response.ok)
                        {
                            const urlParams = new URLSearchParams(window.location.search);
                            let ReturnUrl = urlParams.get('ReturnUrl') || '';

                            ReturnUrl = ReturnUrl.replace(/(?:[a-z]+:)?\/\/[^\/]*/i, '');

                            if (ReturnUrl.length > 0)
                            {
                                window.location = ReturnUrl;
                            }
                            else
                            {
                                window.location.replace('Main');
                            }
                        }
                        else if (response.status == 400)
                        {
                            response.json().then(function (error)
                            {
                                _this.errorCreateText = error.Error;
                            });
                        }
                    })
                    .catch(function (r) { });
            },
        },
        mounted: function ()
        {
            try
            {
                var _this = this;

                if (!('fetch' in window))
                {
                    document.getElementById('Unsupported').style.display = "block";
                    document.getElementById('Main').style.display = "none";
                }

                myfetch(this.BasePath + 'api/User/Environment')
                    .then(function (response)
                    {
                        if (response.ok)
                        {
                            response.text().then(function (body)
                            {
                                if (body === "Test" || body === "Development" || body == "Training")
                                    _this.Environment = body;
                            });
                        }
                    });

                if (this.$cookie.get('Login') === "1")
                {
                    _this.login = true;

                    myfetch(_this.BasePath + 'Login/RememberMe', {
                        method: "GET",
                        credentials: "include",
                    })
                        .then(function (response)
                        {
                            if (response.ok)
                            {
                                window.location.replace('Main');
                            }
                        })
                        .catch(function ()
                        {
                            this.snackColor = 'error';
                            this.snackVisible = true;
                            this.snackText = 'Please try and login again.';
                        })
                        .finally(function ()
                        {
                            _this.login = false;
                        });
                }
            }
            catch (exception)
            {
                document.getElementById('Unsupported').style.display = "block";
                document.getElementById('Main').style.display = "none";
            }
        },
        watch: {
        },
        computed:
        {
        }
    });